import React from 'react';
import possibilityImage from '../../assets/possibility.png';
import './possibility.css';

const handleContactClick = () => {
    const whatsappLink = "https://api.whatsapp.com/send?phone=351913834175&text=Ol%C3%A1%2C%20gostaria%20de%20conhecer%20melhor%20os%20servi%C3%A7os%20da%20Info%20Team%20Software";
    window.open(whatsappLink, '_blank');
};
const Possibility = () => (
  <div className="gpt3__possibility section__padding" id="possibility">
    <div className="gpt3__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </div>
    <div className="gpt3__possibility-content">
      <h4>Abra as portas para novas possibilidades: <span style={{ cursor: 'pointer' }} onClick={handleContactClick}>Entre em Contato.</span></h4>
      <h1 className="gradient__text">As possibilidades estão além <br />da sua imaginação, prontas para serem exploradas.</h1>
      <p>Desafie qualquer fronteira com a assistência especializada. Não se limite a pensamentos, mas mergulhe na transformação.
          A nossa equipe é a jornada que você precisa para alcançar suas ideias. A sua busca por resultados começa aqui."</p>
      <h4>Abra as portas para novas possibilidades: <span style={{ cursor: 'pointer' }} onClick={handleContactClick}>Entre em Contato.</span></h4>
    </div>
  </div>
);

export default Possibility;
