import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';
const handleContactClick = () => {
    const whatsappLink = "https://api.whatsapp.com/send?phone=351913834175&text=Ol%C3%A1%2C%20gostaria%20de%20conhecer%20melhor%20os%20servi%C3%A7os%20da%20Info%20Team%20Software";
    window.open(whatsappLink, '_blank');
};
const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="Quem é a ITS?" text="A Info Team Software é um time empenhado na identificação nas maiores demandas de
      seus clientes, e apresentação de soluções inovadoras e inteligentes mediante o desenvolvimento de softwares inteligentes e
      inovadores. Além disso, reúne as boas práticas de marketing digital, conferindo escalabilidade e aderência ao mercado de seus
      projetos, apresentando soluções altamente competitivas e focadas em melhoria constante." />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">As possibilidades estão além da sua imaginação</h1>
      <p onClick={handleContactClick} >Entrar em contato</p>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Projetos" text="Apresentamos soluções práticas para o seu negócio, de forma assertiva e competitiva para o público alvo." />
      <Feature title="Marketing Digital" text="Um bom projeto só terá aderência ao mercado se for bem apresentado ao publico alvo,
      por isso a Info Team Software alavanca os projetos gerados mediante práticas cada vez mais inovadoras de marketing digital e vendas." />
    </div>
  </div>
);

export default WhatGPT3;
