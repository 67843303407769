import React from 'react';
import './cta.css';

const CTA = () => {
    const handleContactClick = () => {
        const whatsappLink = "https://api.whatsapp.com/send?phone=351913834175&text=Ol%C3%A1%2C%20gostaria%20de%20conhecer%20melhor%20os%20servi%C3%A7os%20da%20Info%20Team%20Software";
        window.open(whatsappLink, '_blank');
    };

    return (
        <div className="gpt3__cta" id="contato">
            <div className="gpt3__cta-content">
                <p>Abra as portas para novas possibilidades: Entre em Contato.</p>
                <h3>Entre em contato hoje & comece a explorar infinitas possibilidades.</h3>
            </div>
            <div className="gpt3__cta-btn">
                <button type="button" onClick={handleContactClick}>Entrar em Contato</button>
            </div>
        </div>
    );
};

export default CTA;
