import React, {useState} from 'react'
import './header.css'
import people from '../../assets/people.png'
import ai from '../../assets/ai.png'
import Popup from "../../components/popup-message/Popup";



const Header = () => {
    const [showPopup, setShowPopup] = useState(false);

    const handleReceivingContactClick = () => {
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
    };
  return (
    <div className='gpt3__header section__padding' id='home'>
      <div className='gpt3__header-content'>
        <h1 className='gradient__text'>
            Soluções inovadoras através de softwares inteligentes
        </h1>
        <p>
            Por meio de softwares inteligentes e inovadores, e unindo as melhores praticas no desenvolvimento de soluções assertivas,
            somos um time focado em propor as melhores soluções para seu negocio, unindo as melhores praticas de tecnologia de ponta,
            IA e estratégias de marketing digital.
        </p>

        <div className='gpt3__header-content__input'>
          <input type="email" placeholder='Insira seu email' />
          <button id="receivingContact" onClick={handleReceivingContactClick} type='button'>Receber Contato</button>
        </div>

        <div className='gpt3__header-content__people'>
          <img src={people} alt="people" />
          <p>1,600 Pessoas visitaram este site hoje.</p>
        </div>
      </div>

      <div className="gpt3__header-image">
        <img src={ai} alt="ai-face" />
      </div>
        {showPopup &&  <Popup isOpen={showPopup} onRequestClose={closePopup} /> }
    </div>
  )
}

export default Header
