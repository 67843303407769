import React from 'react';
import gpt3Logo from '../../assets/logoits2.png';
import './footer.css';

const handleContactClick = () => {
    const whatsappLink = "https://api.whatsapp.com/send?phone=351913834175&text=Ol%C3%A1%2C%20gostaria%20de%20conhecer%20melhor%20os%20servi%C3%A7os%20da%20Info%20Team%20Software";
    window.open(whatsappLink, '_blank');
};


const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">Você quer entrar no futuro antes dos outros?</h1>
    </div>

    <div className="gpt3__footer-btn">
      <p onClick={handleContactClick} >Entrar em Contato</p>
    </div>

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={gpt3Logo} alt="gpt3_logo" />
        <p>Brasil, <br /> Todos os direitos reservados.</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Links</h4>
        <p>Social Media</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Company</h4>
        <p>Politica de privacidade</p>
        <p>Contato</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Mantenha em Contato </h4>
        <p>Email us: infoteamsoftech@gmail.com</p>
        <p>(xx)xx-xxxxx</p>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>@2023 Info Team Software. Todos os direitos Reservados..</p>
    </div>
  </div>
);

export default Footer;
