import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData =
    [
      {
        title: 'Desbravando Fronteiras com Tecnologia Revolucionária',
        text: 'Na vanguarda da inovação, nossa empresa molda o futuro com soluções revolucionárias alimentadas por tecnologia de ponta. Criamos um terreno onde suas ideias se transformam em realidade.',
      },
      {
        title: 'Além do Conhecimento, Experiência Transformadora',
        text: 'Não se trata apenas de marketing digital, mas de um mergulho profundo na arte de conectar marcas com audiências. Nossa expertise em estratégias digitais transforma cliques em conversões e espectadores em entusiastas fiéis.',
      },
      {
        title: 'Da Inspiração à Inovação, Cada Projeto Conta',
        text: 'Inovação é nossa linguagem e eficiência é nossa métrica. Cada projeto é uma oportunidade para transcender o comum, misturando tecnologia de ponta com estratégias de marketing visionárias. Juntos, criamos um espetáculo de sucesso que o mundo não pode ignorar.',
      },
    ];

const handleContactClick = () => {
    const whatsappLink = "https://api.whatsapp.com/send?phone=351913834175&text=Ol%C3%A1%2C%20gostaria%20de%20conhecer%20melhor%20os%20servi%C3%A7os%20da%20Info%20Team%20Software";
    window.open(whatsappLink, '_blank');
};

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">Abrace o Amanhã e Transforme-o em Realidade. Adentre o Futuro Agora. & Concretize Suas Conquistas.</h1>
      <p>Abra as portas para novas possibilidades: <span style={{ cursor: 'pointer' }} onClick={handleContactClick}>Entre em Contato.</span></p>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
