import React from 'react';
import './popup.css';

import Modal from 'react-modal';


const Popup = ({isOpen, onRequestClose}) => {

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="popup-modal"
            overlayClassName="popup-overlay"
        >
            <div className="content-wrapper">
                <h1>Enviado!</h1>
                <p>Em breve nossa equipe entrará em contato.</p>
            </div>
        </Modal>
    );
};

export default Popup;
