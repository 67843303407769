import React from 'react';
import Article from '../../components/article/Article';
import { blog01, blog02, blog03, blog04, blog05 } from './imports';
import './blog.css';

const Blog = () => (
  <div className="gpt3__blog section__padding" id="blog">
    <div className="gpt3__blog-heading">
        <h1 className="gradient__text">Em um universo de inovação, <br /> estamos escrevendo o futuro.</h1>
    </div>
    <div className="gpt3__blog-container">
      <div className="gpt3__blog-container_groupA">
        <Article imgUrl={blog01} date="Julho 26, 2023" text="Adoção de tecnologia por empresas brasileiras cresce 15,5% em 2023" />
      </div>

      <div className="gpt3__blog-container_groupB">
        <Article imgUrl={blog02} date="Agosto 13, 2023" text="ONU busca especialistas globais para atuarem em inteligência artificial" />
        <Article imgUrl={blog03} date="Mai 23, 2023" text="Segurança da Informação: como fazer uma gestão segura de dados" />
        <Article imgUrl={blog04} date="mar 05, 2023" text="Saiba o que é LGPD e como proteger seus dados pessoais" />
        <Article imgUrl={blog05} date="Sep 26, 2023" text="GPT-3 and Open  AI is the future. Let us exlore how it is?" />
      </div>
    </div>
  </div>
);

export default Blog;
